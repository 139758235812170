<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Diário geral
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn
            color="success"
            @click="criarGestaoDeAula"
            class="mr-2"
            v-show="$can(['gestoesDeAulas.criar', 'gestoesDeAulas.*'])"
            >Criar Vínculo</v-btn
          >
          <v-spacer />
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="gestoesGeral"
          :loading="carregandoGestoes"
          :items-per-page="15"
          hide-default-footer
          no-data-text="Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="gestaoDeAulas.estaDeletado ? ['deleted'] : []"
                v-for="gestaoDeAulas of items"
                :key="gestaoDeAulas.id"
              >
                <td>{{ gestaoDeAulas.id }}</td>
                <td>
                  {{
                    gestaoDeAulas.turma.franquia ? gestaoDeAulas.turma.franquia.descricao : "- - -"
                  }}
                </td>
                <td>
                  {{ gestaoDeAulas.turma.descricao ? gestaoDeAulas.turma.descricao : "- - -" }}
                </td>

                <td v-if="gestaoDeAulas.disciplinas.length">
                  <div v-for="(item, index) in gestaoDeAulas.disciplinas" :key="index">
                    <v-chip x-small>{{ item.descricao }} </v-chip>
                  </div>
                </td>
                <td v-else>
                  {{ gestaoDeAulas.nomeDisciplina ? gestaoDeAulas.nomeDisciplina : "- - -" }}
                </td>

                <td>
                  {{ gestaoDeAulas.professor.nome ? gestaoDeAulas.professor.nome : "- - -" }}
                </td>
                <td><e-quantidade-tipos-aulas :gestaoDeAula="gestaoDeAulas" /></td>
                <!-- <td>{{ gestaoDeAulas.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!gestaoDeAulas.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verAulas(gestaoDeAulas)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Aulas</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="!gestaoDeAulas.estaDeletado && !gestaoDeAulas.turma.isInfantil"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'notas.lancarNotas',
                          params: { gestaoDeAulasId: gestaoDeAulas.id },
                        }"
                      >
                        <v-icon small>fa fa-file-alt </v-icon>
                      </v-btn>
                    </template>
                    <span>Notas</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="!gestaoDeAulas.estaDeletado && gestaoDeAulas.turma.isInfantil"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'relatorios.visualizar',
                          params: {
                            turmaId: gestaoDeAulas.turma_id,
                            gestaoDeAulasId: gestaoDeAulas.id,
                          },
                        }"
                      >
                        <v-icon small>fa fa-file-invoice </v-icon>
                      </v-btn>
                    </template>
                    <span>Relatório da(e) {{ gestaoDeAulas.turma.curso.descricao }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="
                      !gestaoDeAulas.estaDeletado &&
                      $can(['gestoesDeAulas.atualizar', 'gestoesDeAulas.*'])
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarGestaoDeAula(gestaoDeAulas)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Gestão de aulas </span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="
                      !gestaoDeAulas.estaDeletado &&
                      $can(['gestoesDeAulas.deletar', 'gestoesDeAulas.*'])
                    "
                    color="error"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarGestaoDeAula(gestaoDeAulas)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar Gestão de aulas </span>
                  </v-tooltip>
                  <v-tooltip v-if="gestaoDeAulas.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarGestaoDeAula(gestaoDeAulas)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar Gestão de aulas </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarGestoes"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import GestaoDeAula from "@/Models/GestaoDeAula";

export default {
  data() {
    return {
      carregandoGestoes: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      gestoesGeral: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Escola", value: "turma.franquia.descricao" },
          { text: "Turma", value: "turma.descricao" },
          { text: "Disciplina", value: "disciplina.descricao" },
          { text: "Professor", value: "professor.nome" },
          { text: "Aulas", value: "" },
          { text: "", value: "actions", sortable: false, width: "200px" },
        ],
      },
    };
  },
  async mounted() {
    this.carregandoGestoes = true;
    this.$loaderService.open("Carregando gestoesDeAulas");
    try {
      await this.listarGestoes(this.pageNumber);
    } catch (error) {
      this.$handleError(error);
    }
    this.$loaderService.close();
    this.carregandoGestoes = false;
  },
  watch: {
    carregandoGestoesDeAulas(value) {
      if (value && this.gestoesGeral.length === 0) {
        this.$loaderService.open("Carregando gestoesDeAulas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 2) {
        await this.listarGestoes(0, val);
      }
      if (!val) {
        await this.listarGestoes(0);
      }
    },
  },
  methods: {
    async verAulas(gestaoDeAula) {
      this.$router.push({
        name: "aulas",
        params: { gestaoDeAulaId: gestaoDeAula.id },
      });
    },
    criarGestaoDeAula() {
      this.$router.push({ name: "gestoesDeAulas.createGeral" });
    },
    async listarGestoes(pageNumber, query = null) {
      this.carregandoGestoes = true;
      try {
        const response = await this.$services.gestoesDeAulasService.geralPaginate(
          pageNumber,
          query
        );
        this.gestoesGeral = response.data.map((gestaoDeAulas) => new GestaoDeAula(gestaoDeAulas));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoGestoes = false;
    },
    editarGestaoDeAula(gestaoDeAulas) {
      this.$router.push({
        name: "gestoesDeAulas.editGeral",
        params: { gestaoDeAulasId: gestaoDeAulas.id },
      });
    },
    async deletarGestaoDeAula(gestaoDeAulas) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta gestaoDeAulas?",
        confirmationCode: gestaoDeAulas.id,
        confirmationMessage: `Por favor, digite <strong>${gestaoDeAulas.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a GestaoDeAula");
              try {
                await this.$services.gestoesDeAulasService.deletarGestaoDeAula(gestaoDeAulas);
                this.$toast.success("GestaoDeAula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarGestaoDeAula(gestaoDeAulas) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta gestaoDeAulas?",
        confirmationCode: gestaoDeAulas.id,
        confirmationMessage: `Por favor, digite <strong>${gestaoDeAulas.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a GestaoDeAula");
              try {
                await this.$services.gestoesDeAulasService.recuperarGestaoDeAula(gestaoDeAulas);
                this.$toast.success("GestaoDeAula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
