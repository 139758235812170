import Axios from "@/plugins/Axios";

export class PlanosBimestraisService {
  async planoBimestral(gestaoDeAulaId, bimestre) {
    const response = await Axios().get(
      `planos-bimestrais/plano-bimestral/${gestaoDeAulaId}/${bimestre}`
    );
    return response.data;
  }

  async planoBimestralComplementar(gestaoDeAulaId, bimestre, disciplinaId) {
    const response = await Axios().get(
      `planos-bimestrais/plano-bimestral/${gestaoDeAulaId}/${bimestre}/${disciplinaId}/complementar`
    );
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(`planos-bimestrais/criar-plano-bimestral`, {
      plano,
    });
    return response.data;
  }

  async createOrUpdateComplementar(plano) {
    const response = await Axios().post(`planos-bimestrais/criar-plano-bimestral/complementar`, {
      plano,
    });
    return response.data;
  }
}
export default new PlanosBimestraisService();
